import { RouterModule, Routes } from '@angular/router'
import { NgModule } from "@angular/core"
import { AppLayoutComponent } from './layout/app.layout.component'

const routes: Routes = [
  // auth routes first
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  // view routes
  {
    path: "",
    redirectTo: "sku",
    pathMatch: "full"
  },
  {
    path: "", component: AppLayoutComponent,
    loadChildren: () => import("./views/views.module").then(m => m.ViewsModule)
  },
  // 404: no matching route
  {
    path: "**",
    redirectTo: "/auth/404"
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
