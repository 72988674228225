import { OnInit } from '@angular/core'
import { Component } from '@angular/core'
import { LayoutService } from './service/app.layout.service'
import { Router } from '@angular/router'
import { StoreService } from 'app/services'
import { environment } from 'environments/environment'
import { CompanyDto } from 'app/shared'
// import pkg from 'package.json';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = []
  model1: any[]
  env = environment
  version = "16.1" // pkg.version
  company: CompanyDto
  prod: boolean = false

    constructor(public layoutService: LayoutService,
    private router: Router,
    public store: StoreService) { }

  ngOnInit() {
    const prod = environment.production
    this.model = [{
      items: [
        { label: "Sku", icon: "category", routerLink: ["/sku"] },
        { label: "Map", icon: "public", routerLink: ["/map"] },
        { label: "Storage", icon: "grid_on", visible: !prod, routerLink: ["storage"] },
        { separator: true },
        { label: "Play", icon: "smart_toy", visible: !prod, routerLink: ["/playpen"] },
        { label: "Tasks", icon: "web_asset", visible: !prod, routerLink: ["/tasks"] },
        { label: "Docs", icon: "description", visible: true, routerLink: ["/docs"] },
        { separator: true },
        { label: "Details", icon: "info", visible: !prod, command: () => this.showView("details") },
        { label: "Items", icon: "category", visible: false, command: () => this.showView("items") },
        { label: "NotFound", icon: "not_listed_location", visible: false, routerLink: ["/auth/not-found"] },
        { label: "Denied", icon: "lock", visible: false, routerLink: ["/auth/access-denied"] },
      ]
    }]

    this.model1 = [
      { label: "Sku", icon: "pi pi-fw pi-list", routerLink: ["/sku"] },
      { label: "Map", icon: "pi pi-fw p-map", routerLink: ["/map"] },
      { separator: true, prod: false },
      { label: "Storage", icon: "grid_on", visible: !prod, routerLink: ["storage"] },
      { label: "Details", icon: "summarize", visible: !prod, routerLink: ["details"] },
      { label: "Items", icon: "category", visible: false, routerLink: ["items"] },
      { label: "Empty", icon: "pi pi-cog", visible: !prod, routerLink: ["/empty"] },
      { label: "Not Found", icon: "not_listed_location", visible: false, routerLink: ["/auth/not-found"] },
      { label: "Access Denied", icon: "lock", visible: false, routerLink: ["/auth/access-denied"] },
      { label: "Docs", icon: "pi pi-copy", visible: true, routerLink: ["/docs"] }
    ]

    this.store.company$.subscribe(company => {
      if (company) {
        this.company = company
        const entry = this.model?.find(m => m.label == "Storage")
        if (entry) entry.visible = company.storageEnabled
      }
    })
  }

  showView = (name: string) => this.router.navigate(["", name])
}
