import { Component, HostListener, OnInit } from '@angular/core'
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { PrimeNGConfig } from 'primeng/api'
import { LayoutService } from './layout/service/app.layout.service'
import { Router } from '@angular/router'
import { StoreService, ThemeService } from './services'

@Component({
  selector: 'app-root',
  template: `
    <p-toast></p-toast>
    <ng-http-loader 
      [backdrop]="true"
      [backgroundColor]="'#fff'"
      [debounceDelay]="100"
      [extraDuration]="300"
      [minDuration]="300"
      opacity=0.6
      [backdropBackgroundColor]="'#000'"
      spinner="sk-chasing-dots">
    </ng-http-loader>
    <valdemort-templates></valdemort-templates>
    <router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private store: StoreService,
    private layout: LayoutService,
    private themeService: ThemeService,
    private log: NGXLogger,
  ) {
    const logConfig = this.log.getConfigSnapshot()
    logConfig.level = NgxLoggerLevel.TRACE
    this.log.updateConfig(logConfig)
  }

  ngOnInit() {
    this.store.searchFor$.subscribe(text => {
      //! alert(`Search for: ${text}`)
      this.router.navigate(['details'], { queryParams: { q: text } })
    })
  }

  keys: string = ""
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.//qt.ag/080YB7XYVD
      key == "F3") {
      this.themeService._searchActive = true
      // this.layout.state.searchBarActive = true
    }
    // if (event.code == "Enter") {
    //   this.store.keys$.next(this.keys)
    //   this.keys = ""
    // } else {
    //   this.keys += event.key
    //   console.log(">", event.code, event.key, event.shiftKey)
    // }
  }
}

// https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
/*
default config
  ripple: true,
  inputStyle: 'outlined',
  menuMode: 'compact',
  colorScheme: 'light',
  theme: 'blue',
  scale: 14,
  menuTheme: 'darkgray'

menu themes:
  blue
  cyan
  deeppurple
  green
  white
  indigo
  orange
  pink
  purple
  teal
  darkgray
  bluegray
  brown

component theme:
  blue
  cyan
  deeppurple
  green
  indigo
  lightgreen
  orange
  pink
  purple
  teal

*/
