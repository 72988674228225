import { APP_INITIALIZER, NgModule } from '@angular/core'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppLayoutModule } from './layout/app.layout.module'
import { JwtInterceptor, TimerInterceptor, SharedModule } from 'app/shared'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'

import { NgHttpLoaderModule } from 'ng-http-loader'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { DialogService } from 'primeng/dynamicdialog'
import { MessageService } from 'primeng/api'
import { CmsService, StoreService } from "app/services"
import { Router } from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'
import { environment } from 'environments/environment'
import { PopupService } from "./services"

@NgModule({
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    SharedModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      //             trace    debug   info    log     warning   error  fatal
      colorScheme: ['green', 'teal', 'blue', 'gray', 'orange', 'red', 'darkred'],
      disableFileDetails: true,
      timestampFormat: "ss.SSS"
    }),
    NgHttpLoaderModule.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeAppData,
      deps: [],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimerInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    PopupService,
    DialogService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

function initializeAppData(): () => Promise<any> {
  return () => new Promise((resolve) => {
    // pre-profile initialization
  })
}

/**
 * Factory that returns the API service based on the desired type, mock or http
 * @param http HttpClient instance
 * @param router Router instance
 * @param store StoreSercive instance
 * @param log NGXLogger instance
 * @returns Instance of API service to inject
 * { provide: CmsService, useFactory: apiFactory, deps: [HttpClient, Router, StoreService, DomSanitizer, NGXLogger] },
 */
// function apiFactory(http: HttpClient, router: Router, store: StoreService, sanitizer: DomSanitizer, log: NGXLogger): CmsService {
//   if (environment?.api) {
//     log.trace("HTTP API")
//     return new CmsHttpService(http, router, store, sanitizer, log)
//   } else {
//     log.trace("Mock API")
//     return new CmsMockService(router, store, log)
//   }
// }
