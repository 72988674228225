<div class="layout-topbar">
  <a class="layout-topbar-logo">
    <img src="assets/images/iProof.png" alt="logo">
  </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
    
    <!-- search -->
    <button class="p-link layout-topbar-button" (click)="themeService.searchActive=true">
      <i class="pi pi-search"></i>
      <span>Search</span>
    </button>

    <!-- account -->
    <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
      <i class="pi pi-user"></i>
      <span>Account</span>
    </button>

    <!-- <button class="p-link layout-topbar-button" (click)="themeService.settingsActive(true)">
      <i class="material-icons">home</i>
      <div class="px-2">user@email.com</div>
    </button> -->

    <!-- theme -->
    <button class="p-link layout-topbar-button" (click)="themeService.toggleTheme()">
      <i class="material-icons">contrast</i>
      <span>Theme</span>
    </button>
   
    <!-- slide menu -->
    <button class="p-link layout-topbar-button" (click)="themeService.settingsActive=true">
      <i class="material-icons">menu_open</i>
      <span>Settings</span>
    </button>

  </div>
</div>

<p-menu #menu [popup]="true" [model]="items"></p-menu>
