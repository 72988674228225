import { Component, ElementRef, ViewChild } from '@angular/core'
import { MenuItem, PrimeIcons } from 'primeng/api'
import { LayoutService } from "./service/app.layout.service"
//*
import { ThemeService } from '../services/theme.service'
import { Profile } from 'app/shared'
import { ChangePasswordComponent, ProfileDialogComponent, SwitchDialogComponent } from 'app/components'
import { StoreService, CmsService, PopupService } from 'app/services'
import { PopupType } from 'app/components/popup/popup.component'

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styles: [`
    ::ng-deep .p-menu {
      padding: 0;
      width: 170px;
    }
    ::ng-deep .p-menuitem-link {
      padding: 12px;
    }
    ::ng-deep .p-menuitem-text {
      font-weight: bolder;
      opacity: 0.65;
    }
  `]
})
export class AppTopBarComponent {
  private profile: Profile

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private el: ElementRef,
    protected store: StoreService,
    protected cms: CmsService,
    protected ns: PopupService,
    public themeService: ThemeService) {
    this.items = [
      {
        label: 'Account',
        icon: PrimeIcons.USER,
        command: () => this.editProfile()
      },
      {
        label: 'Switch Company',
        icon: PrimeIcons.SORT_ALT,
        command: () => this.changeCompany()
      },
      {
        label: 'Change Password',
        icon: PrimeIcons.LOCK
      },
      {
        label: 'Logout',
        icon: PrimeIcons.SIGN_OUT,
        command: () => this.cms.logout()
      }
    ]

    this.store.profile$.subscribe(profile => this.profile = profile)
  }

  onSeach() {
    
  }

  async changeCompany() {
    try {
      await this.ns.run(SwitchDialogComponent,
        {
          profile: this.profile,
          icon: "pi-question",
          title: "Saying Hello"
        }
      )
    } catch (e) {
      this.ns.alert("Error", "Unable to switch companies")
    }
  }

  async editProfile() {
    try {
      const doc = new Profile(this.profile)
      const create = false
      const data = { doc, create, companies: [], roles: [] }
      await this.ns.run(ProfileDialogComponent, data)
    } catch (e) {
      this.ns.alert("Error", "Error updating your user account")
    }
  }

  async changePassword() {
    try {
      await this.ns.run(ChangePasswordComponent)
    } catch (e) {
      this.ns.alert("Error", "Error changing password")
    }
  }
}
