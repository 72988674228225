<div class="h-full flex flex-column justify-content-between">
  <ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
      <li app-menuitem-sub *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
      <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
  </ul>

  <div *ngIf="!prod" class="text-center text-green-700">dev&nbsp;{{ version }}</div>
  <div *ngIf="prod" class="text-center text-white">v{{ version }}</div>
</div>
