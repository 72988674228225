import { Component, ElementRef, ViewChild } from '@angular/core';
import { PrimeModule } from 'app/prime.module'
import { LayoutService } from 'app/layout/service/app.layout.service'
import { ThemeService } from '../services/theme.service'

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [PrimeModule],

  template: `
    <p-dialog [(visible)]="visible" [style]="{width: '50vw'}" [breakpoints]="{'992px': '75vw'}"
      [closeOnEscape]="true" [closable]="true" [dismissableMask]="true" [draggable]="false" [modal]="true" (onShow)="focusOnInput()">
      <div class="search-container">
        <i class="pi pi-search"></i>
        <input #input type="text" class="p-inputtext search-input" placeholder="Search" [(ngModel)]="searchText" (keyup)="onInputKeydown($event)"/>
      </div>
    </p-dialog>
  `,
  
  styles: [`
    :host ::ng-deep {
      .p-dialog {
        .p-dialog-header {
            display: none;
        }
        .p-dialog-content {
            padding: 0;
            background: transparent;
        }
      }
    }

    .search-container {
      background: var(--surface-overlay);
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      padding: 2rem;
      border-radius: var(--border-radius);
      position: relative;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border: var(--surface-border);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      pointer-events: all;

      input {
        appearance: none;
        font-size: 1.5rem;
        text-indent: 2.5rem;
        padding: 0.5rem;
        width: 100%;
      }

      i {
        color: var(--text-color-secondary);
        width: 2rem;
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        margin-top: -0.75rem;
        margin-left: 1rem;
      }
    }
  `]
})
export class SearchComponent {
  
  searchText: string = "61D5-EC02"

  outsideClickListener: any

  timeout!: any

  constructor(
    private themeService: ThemeService,
  ) { }

  @ViewChild('input') inputElement!: ElementRef

  onInputKeydown(event: KeyboardEvent) {
    const key = event.which
    if (key === 27 || key === 9 || key === 13) {
      // if (key === 13) this.store.searchFor$.next(this.searchText)
      this.themeService._searchActive = false
    }
  }

  focusOnInput() {
    this.timeout = setTimeout(() => this.inputElement.nativeElement.focus(), 1)
  }

  get visible(): boolean {
    return this.themeService._searchActive
  }

  set visible(_val: boolean) {
    this.themeService._searchActive = _val
  }
}
