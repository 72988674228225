import { ChangeDetectorRef, Component, Host, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { LayoutService } from 'app/layout/service/app.layout.service'
import { MenuService } from 'app/layout/app.menu.service'
import { PrimeModule } from 'app/prime.module'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-menuitem-sub]',
  standalone: true,
  imports: [PrimeModule],
  template: `
		<ng-container>
			<a *ngIf="((item.routerLink || item.command) && !item.items) && item.visible !== false" (click)="itemClick($event)" [ngClass]="item.class" 
			   [routerLink]="item.routerLink" routerLinkActive="active-route" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{ paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
               [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment" 
               [skipLocationChange]="item.skipLocationChange" [replaceUrl]="item.replaceUrl" [state]="item.state" [queryParams]="item.queryParams"
               [attr.target]="item.target" tabindex="0" pRipple>
        <div class="menu-stack">
          <i *ngIf="item.icon.startsWith('pi')" [ngClass]="item.icon" class="layout-menuitem-icon"></i>
	  			<div *ngIf="!item.icon.startsWith('pi')" class="material-icons layout-menuitem-icon">{{item.icon}}</div>
	  			<div class="layout-menuitem-text">{{ item.label }}</div>
        </div>
			</a>

			<ul *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation">
				<ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
					<li app-menuitem-sub [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
				</ng-template>
			</ul>
		</ng-container>
    `,
  animations: [
    trigger('children', [
      state('collapsed', style({
        height: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class MenuitemComponent implements OnInit, OnDestroy {

  @Input() item: any

  @Input() index!: number

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean

  @Input() parentKey!: string

  active = false;

  menuSourceSubscription: Subscription

  menuResetSubscription: Subscription

  key: string = "";

  constructor(public layoutService: LayoutService, private cd: ChangeDetectorRef, public router: Router, private menuService: MenuService) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active = (value.key === this.key || value.key.startsWith(this.key + '-')) ? true : false
        }
        else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active = false
          }
        }
      })
    })

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false
    })

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(params => {
        if (this.item.routerLink) {
          this.updateActiveStateFromRoute()
        }
      })
  }

  ngOnInit() {
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index)

    if (this.item.routerLink) {
      this.updateActiveStateFromRoute()
    }
  }

  updateActiveStateFromRoute() {
    let activeRoute = this.router.isActive(this.item.routerLink[0], { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' })

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true })
    }
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault()
      return
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item })
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active
    }

    this.menuService.onMenuStateChange({ key: this.key })
  }

  get submenuAnimation() {
    return this.root ? 'expanded' : (this.active ? 'expanded' : 'collapsed')
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe()
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe()
    }
  }
}
