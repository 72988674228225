import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppMenuComponent } from './app.menu.component'
import { AppMenuitemComponent } from './app.menuitem.component'
import { RouterModule } from '@angular/router'
import { AppTopBarComponent } from './app.topbar.component'
import { AppFooterComponent } from './app.footer.component'
import { AppSidebarComponent } from "./app.sidebar.component"
import { AppLayoutComponent } from "./app.layout.component"
//*
import { MenuitemComponent } from 'app/components/menuitem.component'
import { PrimeModule } from '../prime.module';
import { SearchComponent } from '../components/search.component'
import { SlidingPanelComponent } from 'app/components/sliding-panel.component'

@NgModule({
  declarations: [
    AppMenuitemComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppSidebarComponent,
    AppLayoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    //*
    MenuitemComponent,
    PrimeModule,
    SearchComponent,
    SlidingPanelComponent
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
